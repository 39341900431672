





































import {
  Component, Vue,
} from 'vue-property-decorator';
import EditRetirementFundSuccessViewModel
  from '@/vue-app/view-models/components/goals-dashboard/edit-plan-goals/edit-retirement-fund-goal-plan/edit-retirement-fund-success-view-model';

@Component({ name: 'EditRetirementFundSuccess' })
export default class EditRetirementFundSuccess extends Vue {
  edit_retirement_fund_success_view_model = Vue.observable(
    new EditRetirementFundSuccessViewModel(this),
  );
}
